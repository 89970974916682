import { useEffect } from "react";
import { Button, Link } from "@mui/material";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { ThemeProvider } from "../theme-provider/theme-provider";

export default function GlobalError({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <ThemeProvider>
      <Container
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          minWidth: "100vw",
          textAlign: "center",
          backgroundColor: "#F0F8FF",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          color="primary"
          fontWeight="bold"
        >
          Oops! Something went wrong
        </Typography>
        <Typography variant="h6" gutterBottom>
          We're sorry, but an unexpected error occurred.
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            href="/"
            sx={{ minWidth: 120 }}
          >
            Back To Home
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
          If the problem persists, please contact our support team.
        </Typography>
      </Container>
    </ThemeProvider>
  );
}
