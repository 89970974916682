import * as ai from "../constants/ai";

const initialState = {
  isHistoryLoading: false,
  isMessageLoading: false,
  chatHistory: [],
  selectedChat: null,
};

export default function aiReducer(state = initialState, action) {
  switch (action.type) {
    case ai.IS_HISTORY_LOADING:
      return {
        ...state,
        isHistoryLoading: true,
      };
    case ai.IS_MESSAGE_LOADING:
      return {
        ...state,
        isMessageLoading: true,
      };
    case ai.UPDATE_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload,
        isHistoryLoading: false,
      };
    case ai.UPDATE_CHAT_MESSAGE:
      return {
        ...state,
        selectedChat: action.payload,
        isMessageLoading: false,
      };
    case ai.UPDATE_CHAT_HISTORY_ERROR:
      return {
        ...state,
        isHistoryLoading: false,
      };
    case ai.UPDATE_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        isMessageLoading: false,
      };
    default:
      return state;
  }
}
