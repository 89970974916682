/* eslint-disable no-unneeded-ternary */
/* eslint-disable default-param-last */
import { AppConstants } from "../../lib/constants";
import * as auth from "../constants/auth";

const initialState = {
  access: null,
  refresh: null,
  isAuthenticated: false,
  isLoading: false,
  user: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case auth.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case auth.USER_LOADED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true,
      };
    case auth.UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case auth.LOGIN_SUCCEED:
      localStorage.setItem(
        AppConstants.STORAGE_KEYS.USER_SESSION,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true,
      };
    case auth.USER_LOGOUT:
      localStorage.removeItem(AppConstants.STORAGE_KEYS.USER_SESSION);
      return {};
    case auth.AUTH_ERROR:
      localStorage.removeItem(AppConstants.STORAGE_KEYS.USER_SESSION);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
