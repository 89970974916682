import { generateHealthBaseLine } from "../../api/ai.api";
import * as api from "../../api/healthdata.api";
import * as constant from "../constants/healthdata.constants";

export const loadApplicationData = () => async (dispatch, getState) => {
  dispatch({ type: constant.TOGGLE_LOADING });

  try {
    const requests = await Promise.all([
      api.patientAPI.getAll(),
      api.contactInformationAPI.getAll(),
      api.emergencyInformationAPI.getAll(),
      api.socialOccupationalHistoryAPI.getAll(),
      api.medicationsAndAllergiesAPI.getAll(),
      api.chronicConditionsAndMedicalHistoryAPI.getAll(),
      api.surgicalAndHospitalizationHistoryAPI.getAll(),
      api.lifestyleAndPreventiveCareAPI.getAll(),
      api.doctorAPI.getAll(),
      api.baseLineRecommendationAPI.getAll(),
      api.currentTreatmentAPI.getAll(),
      api.mediationPrescriptionAPI.getAll(),
      api.labDiagnosticAPI.getAll(),
      api.imagingAPI.getAll(),
      api.generalMetricAPI.getAll(),
    ]);

    if (requests[0].ok) {
      dispatch({
        type: constant.UPDATE_BASIC_INFORMATION,
        payload: requests[0].data,
      });
    }

    if (requests[1].ok) {
      dispatch({
        type: constant.UPDATE_CONTACT_INFORMATION,
        payload: requests[1].data,
      });
    }

    if (requests[2].ok) {
      dispatch({
        type: constant.UPDATE_EMERGENCY_INFORMATION,
        payload: requests[2].data,
      });
    }

    if (requests[3].ok) {
      dispatch({
        type: constant.UPDATE_SOCIAL_INFORMATION,
        payload: requests[3].data,
      });
    }

    if (requests[4].ok) {
      dispatch({
        type: constant.UPDATE_MEDICATION_INFORMATION,
        payload: requests[4].data,
      });
    }

    if (requests[5].ok) {
      dispatch({
        type: constant.UPDATE_CONDITION_HISTORY_INFORMATION,
        payload: requests[5].data,
      });
    }

    if (requests[6].ok) {
      dispatch({
        type: constant.UPDATE_SURGICAL_HISTORY_INFORMATION,
        payload: requests[6].data,
      });
    }

    if (requests[7].ok) {
      dispatch({
        type: constant.UPDATE_LIFESTYLE_INFORMATION,
        payload: requests[7].data,
      });
    }
    if (requests[8].ok) {
      dispatch({
        type: constant.UPDATE_MEMBERS_LIST,
        payload: requests[8].data,
      });
    }
    if (requests[9].ok) {
      dispatch({
        type: constant.UPDATE_BASELINE_RECOMMENDATION,
        payload: requests[9].data,
      });
    }
    if (requests[10].ok) {
      dispatch({
        type: constant.UPDATE_CURRENT_TREATMENT,
        payload: requests[10].data,
      });
    }
    if (requests[11].ok) {
      dispatch({
        type: constant.UPDATE_MEDICATION_PRESCRIPTION,
        payload: requests[11].data,
      });
    }
    if (requests[12].ok) {
      dispatch({
        type: constant.UPDATE_LAB_DIAGNOSTIC,
        payload: requests[12].data,
      });
    }
    if (requests[13].ok) {
      dispatch({
        type: constant.UPDATE_IMAGING,
        payload: requests[13].data,
      });
    }
    if (requests[14].ok) {
      dispatch({
        type: constant.UPDATE_GENERAL_METRIC,
        payload: requests[14].data,
      });
    }
  } catch (error) {
    console.error("Failed to load application data", error);
  } finally {
    dispatch({ type: constant.TOGGLE_LOADING });
  }
};

export const getMemberBaseline = async () => async (dispatch) => {
  const request = await api.baseLineRecommendationAPI.getAll();
  if (request.ok) {
    dispatch({
      type: constant.UPDATE_BASELINE_RECOMMENDATION,
      payload: request.data,
    });
  }
};

export const generateBaselineAI = () => async (dispatch) => {
  dispatch({ type: constant.SET_BASELINE_LOADING });

  const request = await generateHealthBaseLine();
  if (request.ok) {
    dispatch({ 
      type: constant.UPDATE_BASELINE_RECOMMENDATION,
      payload: request.data,
    });
    return;
  }

  dispatch({ type: constant.SET_BASELINE_ERROR });
};
