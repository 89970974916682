import React from "react";
import GlobalError from "./GlobalError"; // Import your GlobalError component
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    Sentry.captureException(error);
  }

  handleReset = () => {
    this.setState({ hasError: false, error: null });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Render your GlobalError component when an error occurs
      return (
        <GlobalError
          error={this.state.error}
          reset={this.handleReset}
        />
      );
    }

    // Render children when no error occurs
    return this.props.children;
  }
}

export default ErrorBoundary;
