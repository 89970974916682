import { http } from "./config";
import { store } from "../store";
import { AppConstants } from "../lib/constants";

// Function to get the authorization token
const getAuthToken = () => {
  const state = store.getState();
  return state.auth?.access || "";
};

// Helper to add headers to requests
const authHeaders = () => ({
  headers: { Authorization: `Bearer ${getAuthToken()}` },
});

export const getMessageHistory = () =>
  http.get(AppConstants.HTTP.AI_CONVERSATION, {}, authHeaders());


export const sendMessage = (payload) =>
  http.post(AppConstants.HTTP.AI_CONVERSATION, payload, authHeaders());


export const generateHealthBaseLine = (payload) =>
  http.get(AppConstants.HTTP.AI_ANALAYZE_HEALTH_DATA, payload, authHeaders());


