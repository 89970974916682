export const UPDATE_BASIC_INFORMATION = 'UPDATE_BASIC_INFORMATION';
export const UPDATE_CONTACT_INFORMATION = 'UPDATE_CONTACT_INFORMATION';
export const UPDATE_EMERGENCY_INFORMATION = 'UPDATE_EMERGENCY_INFORMATION';
export const UPDATE_SOCIAL_INFORMATION = 'UPDATE_SOCIAL_INFORMATION';
export const UPDATE_MEDICATION_INFORMATION = 'UPDATE_MEDICATION_INFORMATION';
export const UPDATE_CONDITION_HISTORY_INFORMATION = 'UPDATE_CONDITION_HISTORY_INFORMATION';
export const UPDATE_SURGICAL_HISTORY_INFORMATION = 'UPDATE_SURGICAL_HISTORY_INFORMATION';
export const UPDATE_LIFESTYLE_INFORMATION = 'UPDATE_LIFESTYLE_INFORMATION';
export const UPDATE_MEMBERS_LIST = 'UPDATE_MEMBERS_LIST';

export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const UPDATE_BASELINE_RECOMMENDATION = 'UPDATE_BASELINE_RECOMMENDATION';

export const SET_BASELINE_LOADING = 'SET_BASELINE_LOADING';
export const SET_BASELINE_ERROR = 'SET_BASELINE_ERROR';



export const UPDATE_CURRENT_TREATMENT = 'UPDATE_CURRENT_TREATMENT';
export const UPDATE_MEDICATION_PRESCRIPTION = 'UPDATE_MEDICATION_PRESCRIPTION';
export const UPDATE_LAB_DIAGNOSTIC = 'UPDATE_LAB_DIAGNOSTIC';
export const UPDATE_IMAGING = 'UPDATE_IMAGING';
export const UPDATE_GENERAL_METRIC = 'UPDATE_GENERAL_METRIC';
