import { http } from "./config";
import { store } from "../store";

// Function to get the authorization token
const getAuthToken = () => {
  const state = store.getState();
  return state.auth?.access || "";
};

// Helper to add headers to requests
const authHeaders = () => ({
  headers: { Authorization: `Bearer ${getAuthToken()}` },
});

// API calls for doctor
export const doctorAPI = {
  getAll: () => http.get("/api/doctor/patients/", {}, authHeaders()),
};

// API calls for Patient
export const patientAPI = {
  getAll: () => http.get("/api/patients/", {}, authHeaders()),
  getById: (id) => http.get(`/api/patients/${id}/`, {}, authHeaders()),
  create: (data) => http.post("/api/patients/", data, authHeaders()),
  update: (id, data) => http.put(`/api/patients/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`/api/patients/${id}/`, {}, authHeaders()),
};




// API calls for health and current treatment
export const currentTreatmentAPI = {
  getAll: () => http.get("api/patients/current-treatments/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/current-treatments/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/current-treatments/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/current-treatments/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/current-treatments/${id}/`, {}, authHeaders()),
};

// API calls for health and lifestyle survey
export const mediationPrescriptionAPI = {
  getAll: () => http.get("api/patients/medication-prescriptions/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/medication-prescriptions/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/medication-prescriptions/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/medication-prescriptions/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/medication-prescriptions/${id}/`, {}, authHeaders()),
};

// API calls for health and lifestyle survey
export const labDiagnosticAPI = {
  getAll: () => http.get("api/patients/lab-diagnostics/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/lab-diagnostics/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/lab-diagnostics/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/lab-diagnostics/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/lab-diagnostics/${id}/`, {}, authHeaders()),
};

// API calls for health and lifestyle survey
export const imagingAPI = {
  getAll: () => http.get("api/patients/imagings/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/imagings/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/imagings/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/imagings/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/imagings/${id}/`, {}, authHeaders()),
};

// API calls for health and lifestyle survey
export const generalMetricAPI = {
  getAll: () => http.get("api/patients/general-metrics/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/general-metrics/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/general-metrics/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/general-metrics/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/general-metrics/${id}/`, {}, authHeaders()),
};

export const baseLineRecommendationAPI = {
  getAll: () => http.get('/api/patients/health-baseline/', {}, authHeaders())
}

// API calls for health and lifestyle survey
export const healthLifestyleAPI = {
  getAll: () => http.get("api/patients/lifestyle-survey/", {}, authHeaders()),
  getById: (id) => http.get(`api/patients/lifestyle-survey/${id}/`, {}, authHeaders()),
  create: (data) => http.post("api/patients/lifestyle-survey/", data, authHeaders()),
  update: (id, data) => http.put(`api/patients/lifestyle-survey/${id}/`, data, authHeaders()),
  delete: (id) => http.delete(`api/patients/lifestyle-survey/${id}/`, {}, authHeaders()),
};

// API calls for FamilyHistory
export const familyHistoryAPI = {
  getAll: () => http.get("/api/patients/family-histories/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/family-histories/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/family-histories/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/family-histories/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/family-histories/${id}/`, {}, authHeaders()),
};

// API calls for Lifestyle
export const lifestyleAPI = {
  getAll: () => http.get("/api/patients/lifestyles/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/lifestyles/${id}/`, {}, authHeaders()),
  create: (data) => http.post("/api/patients/lifestyles/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/lifestyles/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/lifestyles/${id}/`, {}, authHeaders()),
};

// API calls for MedicalHistory
export const medicalHistoryAPI = {
  getAll: () => http.get("/api/patients/medical-histories/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/medical-histories/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/medical-histories/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/medical-histories/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/medical-histories/${id}/`, {}, authHeaders()),
};

// API calls for EnvironmentalExposure
export const environmentalExposureAPI = {
  getAll: () =>
    http.get("/api/patients/environmental-exposures/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/environmental-exposures/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/environmental-exposures/", data, authHeaders()),
  update: (id, data) =>
    http.put(
      `/api/patients/environmental-exposures/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/environmental-exposures/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for GeneticInformation
export const geneticInformationAPI = {
  getAll: () =>
    http.get("/api/patients/genetic-information/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/genetic-information/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/genetic-information/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/genetic-information/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/genetic-information/${id}/`, {}, authHeaders()),
};

// API calls for DiagnosticTest
export const diagnosticTestAPI = {
  getAll: () => http.get("/api/patients/diagnostic-tests/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/diagnostic-tests/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/diagnostic-tests/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/diagnostic-tests/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/diagnostic-tests/${id}/`, {}, authHeaders()),
};

// API calls for Symptom
export const symptomAPI = {
  getAll: () => http.get("/api/patients/symptoms/", {}, authHeaders()),
  getById: (id) => http.get(`/api/patients/symptoms/${id}/`, {}, authHeaders()),
  create: (data) => http.post("/api/patients/symptoms/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/symptoms/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/symptoms/${id}/`, {}, authHeaders()),
};

// API calls for Biomarker
export const biomarkerAPI = {
  getAll: () => http.get("/api/patients/biomarkers/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/biomarkers/${id}/`, {}, authHeaders()),
  create: (data) => http.post("/api/patients/biomarkers/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/biomarkers/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/biomarkers/${id}/`, {}, authHeaders()),
};

// API calls for ContactInformation
export const contactInformationAPI = {
  getAll: () =>
    http.get("/api/patients/contact-information/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/contact-information/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/contact-information/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/contact-information/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(`/api/patients/contact-information/${id}/`, {}, authHeaders()),
};

// API calls for EmergencyInformation
export const emergencyInformationAPI = {
  getAll: () =>
    http.get("/api/patients/emergency-information/", {}, authHeaders()),
  getById: (id) =>
    http.get(`/api/patients/emergency-information/${id}/`, {}, authHeaders()),
  create: (data) =>
    http.post("/api/patients/emergency-information/", data, authHeaders()),
  update: (id, data) =>
    http.put(`/api/patients/emergency-information/${id}/`, data, authHeaders()),
  delete: (id) =>
    http.delete(
      `/api/patients/emergency-information/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for SocialOccupationalHistory
export const socialOccupationalHistoryAPI = {
  getAll: () =>
    http.get("/api/patients/social-occupational-history/", {}, authHeaders()),
  getById: (id) =>
    http.get(
      `/api/patients/social-occupational-history/${id}/`,
      {},
      authHeaders()
    ),
  create: (data) =>
    http.post(
      "/api/patients/social-occupational-history/",
      data,
      authHeaders()
    ),
  update: (id, data) =>
    http.put(
      `/api/patients/social-occupational-history/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/social-occupational-history/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for MedicationsAndAllergies
export const medicationsAndAllergiesAPI = {
  getAll: () =>
    http.get("/api/patients/medications-and-allergies/", {}, authHeaders()),
  getById: (id) =>
    http.get(
      `/api/patients/medications-and-allergies/${id}/`,
      {},
      authHeaders()
    ),
  create: (data) =>
    http.post("/api/patients/medications-and-allergies/", data, authHeaders()),
  update: (id, data) =>
    http.put(
      `/api/patients/medications-and-allergies/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/medications-and-allergies/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for ChronicConditionsAndMedicalHistory
export const chronicConditionsAndMedicalHistoryAPI = {
  getAll: () =>
    http.get(
      "/api/patients/chronic-conditions-and-medical-history/",
      {},
      authHeaders()
    ),
  getById: (id) =>
    http.get(
      `/api/patients/chronic-conditions-and-medical-history/${id}/`,
      {},
      authHeaders()
    ),
  create: (data) =>
    http.post(
      "/api/patients/chronic-conditions-and-medical-history/",
      data,
      authHeaders()
    ),
  update: (id, data) =>
    http.put(
      `/api/patients/chronic-conditions-and-medical-history/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/chronic-conditions-and-medical-history/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for SurgicalAndHospitalizationHistory
export const surgicalAndHospitalizationHistoryAPI = {
  getAll: () =>
    http.get(
      "/api/patients/surgical-and-hospitalization-history/",
      {},
      authHeaders()
    ),
  getById: (id) =>
    http.get(
      `/api/patients/surgical-and-hospitalization-history/${id}/`,
      {},
      authHeaders()
    ),
  create: (data) =>
    http.post(
      "/api/patients/surgical-and-hospitalization-history/",
      data,
      authHeaders()
    ),
  update: (id, data) =>
    http.put(
      `/api/patients/surgical-and-hospitalization-history/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/surgical-and-hospitalization-history/${id}/`,
      {},
      authHeaders()
    ),
};

// API calls for LifestyleAndPreventiveCare
export const lifestyleAndPreventiveCareAPI = {
  getAll: () =>
    http.get("/api/patients/lifestyle-and-preventive-care/", {}, authHeaders()),
  getById: (id) =>
    http.get(
      `/api/patients/lifestyle-and-preventive-care/${id}/`,
      {},
      authHeaders()
    ),
  create: (data) =>
    http.post(
      "/api/patients/lifestyle-and-preventive-care/",
      data,
      authHeaders()
    ),
  update: (id, data) =>
    http.put(
      `/api/patients/lifestyle-and-preventive-care/${id}/`,
      data,
      authHeaders()
    ),
  delete: (id) =>
    http.delete(
      `/api/patients/lifestyle-and-preventive-care/${id}/`,
      {},
      authHeaders()
    ),
};
