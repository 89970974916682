export const USER_LOADING = "@AUTH_USER_LOADING";
export const USER_LOADED = "@AUTH_USER_LOADED";
export const USER_LOGOUT = "@AUTH_USER_LOGOUT";

// login
export const LOGIN_SUCCEED = "@AUTH_LOGIN_SUCCEED";
export const AUTH_ERROR = "@AUTH_AUTH_ERROR";

// session
export const SESSION_RESTORE = "@SESSION_RESTORE";
export const SESSION_RESTORED = "@SESSION_RESTORED";
export const UPDATE_USER_DATA = '@AUTH_UPDATE_USER__DATA'