/* eslint-disable no-unneeded-ternary */
/* eslint-disable default-param-last */
import * as healthDataType from "../constants/healthdata.constants.js";

const initialState = {
  isLoading: false,
  patient: null,
  contact: null,
  emergency: null,
  social: null,
  medication: null,
  condition: null,
  surgeries: null,
  lifestyle: null,
  members: [],
  baselines: [],
  currentTreatment: [],
  medicationPrescription: [],
  labDiagnostic: [],
  imaging: [],
  generalMetrics: [],
  labTestRecommendation: [],
  predictiveHealthAnalysis: [],
  geneticInformationAnalysis: [],
  lifestyleAnalysis: [],
  lifestyleRecommendations: [],
  personalizedTreatmentPlan: [],
  baseLineLoading: false,
};

export default function healthDataReducer(state = initialState, action) {
  switch (action.type) {
    case healthDataType.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case healthDataType.SET_BASELINE_LOADING:
      return {
        ...state,
        baseLineLoading: true,
      };
    case healthDataType.SET_BASELINE_ERROR:
      return {
        ...state,
        baseLineLoading: false,
      };
    case healthDataType.UPDATE_MEMBERS_LIST:
      return {
        ...state,
        members: action.payload,
      };
    case healthDataType.UPDATE_BASIC_INFORMATION:
      return {
        ...state,
        patient: action.payload,
      };
    case healthDataType.UPDATE_CONTACT_INFORMATION:
      return {
        ...state,
        contact: action.payload,
      };
    case healthDataType.UPDATE_EMERGENCY_INFORMATION:
      return {
        ...state,
        emergency: action.payload,
      };
    case healthDataType.UPDATE_SOCIAL_INFORMATION:
      return {
        ...state,
        social: action.payload,
      };
    case healthDataType.UPDATE_MEDICATION_INFORMATION:
      return {
        ...state,
        medication: action.payload,
      };
    case healthDataType.UPDATE_CONDITION_HISTORY_INFORMATION:
      return {
        ...state,
        condition: action.payload,
      };
    case healthDataType.UPDATE_SURGICAL_HISTORY_INFORMATION:
      return {
        ...state,
        surgeries: action.payload,
      };
    case healthDataType.UPDATE_LIFESTYLE_INFORMATION:
      return {
        ...state,
        lifestyle: action.payload,
      };
    case healthDataType.UPDATE_CURRENT_TREATMENT:
      return {
        ...state,
        currentTreatment: action.payload,
      };
    case healthDataType.UPDATE_MEDICATION_PRESCRIPTION:
      return {
        ...state,
        medicationPrescription: action.payload,
      };
    case healthDataType.UPDATE_LAB_DIAGNOSTIC:
      return {
        ...state,
        labDiagnostic: action.payload,
      };
    case healthDataType.UPDATE_IMAGING:
      return {
        ...state,
        imaging: action.payload,
      };
    case healthDataType.UPDATE_GENERAL_METRIC:
      return {
        ...state,
        generalMetrics: action.payload,
      };
    case healthDataType.UPDATE_BASELINE_RECOMMENDATION:
      return {
        ...state,
        baselines: action.payload.baselines,
        labTestRecommendation: action.payload.lab_recommendations,
        predictiveHealthAnalysis: action.payload.predictive_health_analysis,
        geneticInformationAnalysis: action.payload.genetic_information_analysis,
        lifestyleAnalysis: action.payload.lifestyle_analysis,
        lifestyleRecommendations: action.payload.lifestyle_recommendation,
        personalizedTreatmentPlan: action.payload.personalized_treatment_plan,
        baseLineLoading: false,
      };
    default:
      return state;
  }
}
