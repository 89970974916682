export const Colors = {
  background: {
    light: "#fffefe",
    light_dim: "#fcfcfc",
    primary_light_dim: "#e9f2f8",
    dark: "#202120",
    dark_dim: "#2a2a2b",
  },
  primary: '#41A8FC',
};
