import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "../../auth/auth-guard";
import { TopHeader } from "../TopHeader";
import ErrorBoundary from "../../error/ErrorBoundary";
import { useSelector } from "react-redux";


const ROLES = {
  MEMBER: "MEMBER",
  ADMIN: "ADMIN",
  DOCTOR: "DOCTOR",
};

export default function DashboardLayout() {

  const { user } = useSelector((state) => state.auth);
  
  return (
    <ErrorBoundary>
      <AuthGuard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          {user?.role?.toUpperCase() !== ROLES.MEMBER && <TopHeader />}
          <Outlet />
        </Box>
      </AuthGuard>
    </ErrorBoundary>
  );
}
