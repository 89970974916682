// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import './index.css';
import App from './App';
import ErrorBoundary from './components/error/ErrorBoundary';

import * as Sentry from '@sentry/react';
import { firebaseConfig } from './lib/firebase';

Sentry.init({
  dsn: '__DSN__',
  // ...
});

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);




