import { Stack, Box, Typography, Avatar } from "@mui/material";
import React from "react";

import { FiBell, FiChevronDown } from "react-icons/fi";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { usePopover } from "../../hooks/use-popover";
import { UserPopover } from "./Popover";
import { useSelector } from "react-redux";
import { DynamicLogo } from "../logo";
import { Colors } from "../../theme";

export const TopHeader = () => {
  const userPopover = usePopover();
  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <Stack
        px={4}
        py={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        bgcolor={Colors.background.light}
        sx={{ borderBottomWidth: 0.3, borderBottomStyle: 'solid', borderBottomColor: '#f2f2f2'}}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <Box href={"#"} sx={{ display: "inline-block", fontSize: 0 }}>
            <DynamicLogo
              colorDark="light"
              colorLight="dark"
              height={32}
              width={122}
            />
          </Box>
          <Typography
            sx={{ display: { xs: "none", sm: "block" } }}
            variant="subtitle1"
          >
            Precision Medicine
          </Typography>
        </Stack>

        {/* user data  */}
        <Stack direction={"row"} spacing={2}>
          <Stack
            display={{ xs: "none", md: "flex" }}
            direction={"row"}
            spacing={1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#E7ECFC",
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <FiBell color={"#115490"} fontSize={"15px"} />
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={userPopover.handleOpen}
            ref={userPopover.anchorRef}
          >
            <Avatar
              sx={{
                borderRadius: "15px",
                cursor: "pointer",
                width: "30px",
                height: "30px",
              }}
              src={""}
            />
          </Stack>
        </Stack>
      </Stack>
      <UserPopover
        anchorEl={userPopover.anchorRef.current}
        onClose={userPopover.handleClose}
        open={userPopover.open}
      />
    </React.Fragment>
  );
};
