import * as aiAPI from "../../api/ai.api";
import * as aiConstant from "../constants/ai";

export const getAIChatHistory = () => async (dispatch, getState) => {
  dispatch({ type: aiConstant.IS_HISTORY_LOADING });

  const request = await aiAPI.getMessageHistory();
  if (request.ok) {
    dispatch({ type: aiConstant.UPDATE_CHAT_HISTORY, payload: request.data });
    return;
  }

  dispatch({ type: aiConstant.UPDATE_CHAT_HISTORY_ERROR });
};

export const sendAIMessage = (messagePayload) => async (dispatch, getState) => {
  dispatch({ type: aiConstant.IS_MESSAGE_LOADING });

  const request = await aiAPI.sendMessage(messagePayload);
  if (request.ok) {
    dispatch({ type: aiConstant.UPDATE_CHAT_MESSAGE, payload: request.data });
    // update the chat history too
    const chatHistory = getState().ai.chatHistory;
    let isNewChat = true;
    const newChatHistory = chatHistory.map((item) => {
      if (item.id === request.data?.id) {
        isNewChat = false;
        return request.data;
      }
      return item;
    });

    dispatch({
      type: aiConstant.UPDATE_CHAT_HISTORY,
      payload: isNewChat ? [request.data, ...chatHistory] : newChatHistory,
    });
    return;
  }

  dispatch({ type: aiConstant.UPDATE_CHAT_MESSAGE_ERROR });
};
