import * as React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { z as zod } from "zod";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

import { verifyEmail } from "../../api/auth.api.js";
import { AppConstants } from "../../lib/constants.js";
import { useNavigate } from "react-router-dom";
import { DynamicLogo } from "../logo.jsx";
import { FormHelperText, Link, Slide } from "@mui/material";

const schema = zod.object({
  code: zod
    .string()
    .length(6, { message: "Verification code must be 6 digits" }),
});

const defaultValues = { code: "" };

export function VerifyEmailForm() {
  const [isPending, setIsPending] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    const verification = localStorage.getItem(
      AppConstants.STORAGE_KEYS.VERIFICATION
    );
    if (verification) {
      const parsedData = JSON.parse(verification);
      setEmail(parsedData?.email);
    }
  }, []);

  const {
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues, resolver: zodResolver(schema) });



  const onSubmit = React.useCallback(async () => {
    setIsPending(true);

    const payload = {
      code: otp,
      email: email,
    };

    const request = await verifyEmail(payload);
    if (request.ok) {
      toast.success("You have successfully verified your email account");
      navigate("/login");
      return;
    } else {
      let message = "Invalid verification code";
      if (request?.data?.non_field_errors) {
        message = request?.data?.non_field_errors[0];
      }
      setError("root", { type: "server", message });
    }

    setIsPending(false);
  }, [otp, email, navigate, setError]);

  const handleResendEmail = async () => {
    // todo: handle resend email
  };

  return (
    <Stack spacing={3} minWidth={{ xs: "100%", sm: 400 }} paddingX={2}>
      <Stack spacing={1}>
        <Stack sx={{ display: { xs: "flex", sm: "none" } }}>
          <DynamicLogo height={40} width={100} />
        </Stack>
        <Slide direction="left" in timeout={600}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontFamily: "serif", fontWeight: "500" }}
          >
            Please check your email
          </Typography>
        </Slide>
        <Typography variant="body1" component="p">
          We've sent a code to <span style={{ color: "#115490" }}>{email}</span>
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <OtpInput
            value={otp}
            onChange={(e) => {
              if (isNaN(e)) {
                return;
              }
              setValue("code", e);
              setOtp(e);
            }}
            numInputs={6}
            renderSeparator={<span style={{ width: "10px" }} />}
            renderInput={(props) => <input {...props} />}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              width: "50px",
              height: "50px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "24px",
              textAlign: "center",
            }}
          />
          {errors.code ? (
            <FormHelperText sx={{ color: "red" }}>
              {errors.code.message}
            </FormHelperText>
          ) : null}
          {errors.root ? (
            <Alert color="error">{errors.root.message}</Alert>
          ) : null}
          <Button
            fullWidth
            disabled={isPending || otp.length < 6}
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            Verify
          </Button>
          <Typography color="text.secondary" variant="body2" textAlign="center">
            Didn&apos;t receive an email?{" "}
            <Link
              component="button"
              onClick={handleResendEmail}
              underline="hover"
              variant="subtitle2"
            >
              Resend
            </Link>
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
}
