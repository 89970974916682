import { combineReducers } from "redux";
import auth from "./auth";
import healthDataReducer from "./healthdata";
import aiReducer from './ai'; 

export default combineReducers({
  auth,
  health: healthDataReducer,
  ai: aiReducer
});
