import * as React from "react";

export function usePopover() {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  console.log(open);

  return { anchorRef, handleClose, handleOpen, open };
}
