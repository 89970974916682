
export const firebaseConfig = {
  apiKey: "AIzaSyB7bfHAi0j_nO9_QnhmSoVfYXj3oYI5htY",
  authDomain: "livemoreai.firebaseapp.com",
  projectId: "livemoreai",
  storageBucket: "livemoreai.firebasestorage.app",
  messagingSenderId: "696813582437",
  appId: "1:696813582437:web:e433712a6fede15446ee8c",
  measurementId: "G-4MMY3G46BS"
};

