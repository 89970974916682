export const AppConstants = {
  STORAGE_KEYS: {
    USER_SESSION: "user_session",
    VERIFICATION: "verification",
  },
  HTTP: {
    LOGIN: "/auth/login/",
    REGISTER: "/auth/register/",
    VERIFY_EMAIL: "/auth/verify-email/",
    GET_USER: "/auth/user/",
    BETA_SIGNUP_STATUS: "/auth/programs/beta-signup",
    TOKEN_REFRESH: "/auth/token/refresh/",
    AI_CONVERSATION: "/api/assistant/",
    AI_ANALAYZE_HEALTH_DATA: "/api/assistant/health-analysis/",
  },
  URL: {
    PROD: "https://dashboard.livemoreai.com",
    DEV: "http://192.168.0.139:8000",
  },
};

export const TABS = { PERSONAL_DETAILS: "details", CHAT_HISTORY: "history" };
